import PhoneInput from "react-phone-input-2";

const PhoneCustom3 = ({ formik }) => {
  const placeHolderLength = 3;

  return (
    <div className="mb-2 w-[100%]">
      <span
        style={{
          "--placeholder-text": "-10.5px",
        }}
        className={` input-group `}
      >
        <PhoneInput
          className={`txtfieldscontact2   ${formik.touched["phone"] && formik.errors["phone"] ? "cntforms3" : "txtfieldscontacthome"}  sm:w-[20rem] text-[13px]  text-secondary outline-none `}
          country={"us"} // Default country (you can change this as needed)
          value={formik.values.phone}
          onChange={(value, country) => {
            formik.setFieldValue("phone", value);
          }}
          inputStyle={{
            width: "100%",
            color: "white",
            outline: "none",
            fontSize: "13px",
            paddingTop: "16px",
            backgroundColor: "#0A1019",
            color: "white",
            border: "none"
          }}
          containerStyle={{ width: "100%" , backgroundColor: "#0A1019",}}
          inputProps={{
            name: "phone",
            required: true,
            placeholder: "Phone",
            outline: "none",
            fontSize: "13px",
            
            
          }}
          onBlur={formik.handleBlur}
        />
      </span>

      {/* {formik.touched.phone && formik.errors.phone ? <div className="error">{formik.errors.phone}</div> : null} */}
    </div>
  );
};

export default PhoneCustom3;
