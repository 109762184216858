const InputComponent3 = ({
    className,
    value,
    placeholder,
    formik,
    required,
  }) => {
    const placeHolderLength = placeholder.length ? placeholder.length : 1;
  
    return (
      <div className="mb-2 w-[100%] relative">
        <span
          style={{
            "--placeholder-text": "-10px",
            fontSize: "100%",
          }}
          className={` ${required === true ? "input-group" : ""}   `}
        >
          <input
            type="text"
            name={value}
            id={value}
            className={` ${formik.touched[value] && formik.errors[value] ? "cntforms3" : "txtfieldscontacthome"}  `}
            value={formik.values[value]}
            onChange={formik.handleChange}
            required={required}
            placeholder={placeholder}
            onBlur={formik.handleBlur}
            style={{
              "::placeholder": { color: "red" }, // This might not work in all browsers
            }}
          />
        </span>
  
        {/* use this to show form Error */}
        {/* {formik.touched[value] && formik.errors[value] ? (
        <div className="error">{formik.errors[value]}</div>
      ) : null} */}
      </div>
    );
  };
  
  export default InputComponent3;
  